@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@200;400;600;800&display=swap');



body {
  font-family: 'Noto Sans KR', sans-serif;
  /* background-color: rgb(240, 242, 245); */
  /* flex-direction: column;
  align-items: center; */

}

Table thead th {
  font-size: 16px;
}

Table tbody td {
  font-size: 14px;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Viewport Height를 사용해 화면 전체 높이를 차지하도록 설정 */
}

.navbar-collapse {
  justify-content: flex-end;
}

/* ListGroup의 테두리 제거 */
.no-border .list-group-item {
  border: none;
}

/* ListGroup의 외곽 테두리 제거 */
.no-border {
  border: none;
  box-shadow: none; /* 그림자도 제거하고 싶을 경우 추가 */
}

.no-deco{  
  text-align: center;  
  text-decoration: none;
}

.App{
  font-family: 'Noto Sans KR', sans-serif;
  text-align: center;
  margin-top: 50px;
}

.App-header {
  background-color: #f3f3f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Viewport Height를 사용해 화면 전체 높이를 차지하도록 설정 */
}

.App-link {
  color: #61dafb;
}

.tab-title{
  font-size: 1.2rem;
}

.bold_800{
  font-weight: 800;
}

.bold_600{
  font-weight: 600;
}

.bold_400{
  font-weight: 400;
}

.bold_200{
  font-weight: 200;
}

.fontsize_07rem{
  font-size: 0.7rem;
}

.fontsize_08rem{
  font-size: 0.8rem;
}

.fontsize_09rem{
  font-size: 0.9rem;
}

.fontsize_1rem{
  font-size: 1rem;
}

.fontsize_12rem{
  font-size: 1.2rem;
}

.fontsize_15rem{
  font-size: 1.5rem;
}
.fontsize_16rem{
  font-size: 1.6rem;
}

.fontsize_2rem{
  font-size: 2rem;
}

.fontsize_9px{
  font-size: 9px;
}

.fontsize_10px{
  font-size: 10px;
}

.fontsize_12px{
  font-size: 12px;
}

.fontsize_14px{
  font-size: 14px;
}

.lineheight_09{
  line-height:0.9
}

.lineheight_11{
  line-height:1.1
}

.lineheight_12{
  line-height:1.2
}

.label {
  width: 200mm;
  height: 102mm;
  border: 1px solid whitesmoke;
  padding: 0px;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.label_pantos {
  width: 157mm;
  height: 107mm;
  border: 1px solid whitesmoke;
  padding: 0px;
  display: flex;
  flex-direction: row;
  background-color: white;
}

.column1 {
  width: 1mm;
  border: 1px solid white;
}

.column2 {
  width: 2mm;
  border: 1px solid white;
}

.column3 {
  width: 32mm;
  border: 1px solid white;
}

.column5 {
  width: 5mm;
  border: 1px solid white;
}

.column8 {
  width: 8mm;
  border: 1px solid white;
}

.column20 {
  width: 20mm;
  border: 1px solid white;
}

.column60 {
  width: 60mm;
  border: 1px solid white;
}

.column78 {
  width: 78mm;
  border: 1px solid white;
}

.row15 {
  height: 15mm;
  border: 1px solid white;
}
.row16 {
  height: 16mm;
  border: 1px solid white;
}
.row17 {
  height: 17mm;
  border: 1px solid white;
}
.row18 {
  height: 18mm;
  border: 1px solid white;
}
.row20 {
  height: 20mm;
  border: 1px solid white;
}
.row21 {
  height: 21mm;
  border: 1px solid white;
}

.row25 {
  height: 25mm;
  border: 1px solid white;
}

.row35 {
  height: 35mm;
  border: 1px solid white;
}

.row39 {
  height: 39mm;
  border: 1px solid white;
}

.row40 {
  height: 40mm;
  border: 1px solid white;
}

.item {
  align-items: center;
  color: #0c0c0c;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  width: 30px;
} 

.disabled-page {
  color: #808e9b;
}

.active {
  border: solid 1px #808e9b;
  /* border-radius: 40px; */
  color: #808e9b;
}

.break-me {
}

.next {
  border-left: solid 1px #808e9b;
  font-size: 14px;
  height: 50px;
  position: absolute;
  right: 0;
  width: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagination {
  align-items: center;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 700px;
  border: solid 1px #6c757d;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  border-right: solid 1px #808e9b;
  font-size: 14px;
  height: 50px;
  left: 0;
  position: absolute;
  width: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.cell {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px; /* 확실한 너비 제한 */
}


.cell:hover {
  overflow: visible;
  white-space: normal;
  background-color: #f0f0f0;
}

.cell-300 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px; /* 확실한 너비 제한 */
}

.cell-300:hover {
  overflow: visible;
  white-space: normal;
  background-color: #f0f0f0;
}


.tooltip-inner {
  max-width: none;
}

  .bg-searchbox{
    background-color: #1abc9c;
  }


@media (min-width: 2500px) {
  .cell {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px; /* 확실한 너비 제한 */
  }

  .cell-300 {
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px; /* 확실한 너비 제한 */
  }

}